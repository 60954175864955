<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 权属暂无页面 -->
    <div class="not">
        <main-steps :active="1"></main-steps>
        <div class="not_main">
            <div class="not_main_top">
                <span><img :src="titleIcon" alt="" /></span>
                <span class="not_main_top_font">选择权属</span>
                <el-row>
                    <el-input
                        v-model.trim="search"
                        placeholder="输入搜索关键词"
                        :suffix-icon="getclass"
                        @focus="focus"
                        @blur="focus"
                    >
                    </el-input>
                    <el-button type="primary" class="add" @click="goOwnership">新增</el-button>
                </el-row>
            </div>
            <div class="not_img">
                <img :src="notIcon" alt="" />
                <p>暂无相关权属，请点击"新增"按钮添加</p>
            </div>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';

export default {
    name: 'noownership',
    components: {
        'main-steps': MainSteps, // 步骤条组件
    },
    data() {
        return {
            titleIcon: require('@/assets/img/title2.png'),
            notIcon: require('@/assets/img/emptys.png'),
            search: '',
            off: true,
        };
    },
    methods: {
        goOwnership() {
            let obj = {
                updata: true,
                cp_id: '',
            };
            sessionStorage.setItem('own', JSON.stringify(obj));
            this.$router.push({
                path: '/ownership',
            });
        },
        focus() {
            this.off = !this.off;
        },
    },
    computed: {
        getclass() {
            return this.off ? 'el-input__icon el-icon-search add' : 'el-input__icon el-icon-search on add';
        },
    },
    watch: {
        radio(newValue) {
            // console.log(newValue);
            if (newValue === '组织') {
                this.isshow = true;
            } else {
                this.isshow = false;
            }
        },
    },
};
</script>

<style scoped>
::v-deep [class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-pagination [class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-input__icon:hover {
    cursor: pointer;
    color: #2d63e0;
}
::v-deep .el-input__icon.on {
    color: #2d63e0;
}
.not {
    /* position: relative; */
    background: #ffffff;
    box-shadow: 0 10px 32px 0 rgba(175, 188, 220, 0.02);
    border-radius: 4px;
    width: 1200px;
    height: auto;
    /* height: 1192px; */
    margin: auto;
}
.not_main {
    height: 500px;
    width: 800px;
    margin: auto;
}
.not_main_top {
    width: 816px;
    height: 76px;
    margin-left: 45px;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
}

.not_main_top span img {
    width: 11px;
    height: 11px;
    margin-left: -15px;
    margin-top: 22px;
}

.not_main_top_font {
    font-size: 20px;
    color: #1e2024;
    line-height: 20px;
    width: 80px;
    height: 20px;
    margin-top: 20px;
    margin-left: -735px;
}

.el-input {
    width: 214px;
    height: 32px;
    margin-top: 18px;
    margin-left: -500px;
}

.el-button {
    width: 80px;
    height: 40px;
    background: #2d63e0;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    margin-left: 20px;
}

.not_img {
    width: 275px;
    height: 140px;
    background-color: #fff;
    margin: 100px auto;
}

.not_img img {
    width: 169px;
    height: 169px;
    /* margin: auto; */
}

.not_img p {
    font-size: 16px;
    color: #767981;
    margin-top: 20px;
}
</style>